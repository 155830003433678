import React from "react"
import PageRoot from "../components/PageRoot"
import { graphql } from "gatsby"
import { renderCarouselItem } from "../utils"
import ContainerWithPadding from "../components/ContainerWithPadding"
import { useTranslation } from "react-i18next"
import EventureGrid from "../components/EventureGrid"

const AllCoursePage = ({ data }) => {
  const { t } = useTranslation()
  const courseList = data.eventure.courses

  return (
    <PageRoot title={t("label:courses")} showTitle>
      <div className="category-all-wrapper">
        <ContainerWithPadding size={"large"}>
          <EventureGrid
            dataSource={courseList}
            renderItem={renderCarouselItem}
          />
        </ContainerWithPadding>
      </div>
    </PageRoot>
  )
}

export const query = graphql`
  query allCourses {
    eventure {
      courses(order_by: { title: asc }, where: { state: { _eq: PUBLISHED } }) {
        ...PreviewCardCourse
      }
    }
  }
`

export default AllCoursePage
